import { axios } from '@/utils/request'

/**
 * 分頁查詢文件信息表
 *
 * @author yubaoshan
 * @date 2020/6/30 00:20
 */
export function sysFileInfoPage (parameter) {
  return axios({
    url: '/sysFileInfo/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 獲取全部文件信息表
 *
 * @author yubaoshan
 * @date 2020/6/30 00:20
 */
export function sysFileInfoList (parameter) {
  return axios({
    url: '/sysFileInfo/list',
    method: 'get',
    params: parameter
  })
}

/**
 * 上傳文件
 *
 * @author yubaoshan
 * @date 2020/6/30 00:20
 */
export function sysFileInfoUpload (parameter) {
  return axios({
    url: '/sysFileInfo/upload',
    method: 'post',
    data: parameter
  })
}

/**
 * 下載文件
 *
 * @author yubaoshan
 * @date 2020/6/30 00:20
 */
export function sysFileInfoDownload (parameter) {
  return axios({
    url: '/sysFileInfo/download',
    method: 'get',
    params: parameter,
    responseType: 'blob'
  })
}

/**
 * 記錄下載與預覽及接收情況
 *
 * @author xyl
 * @date 2020/6/30 00:20
 */
 export function sysFileWditAccept (parameter) {
  return axios({
    url: '/accept/editAccept ',
    method: 'post',
    data: parameter
  })
}

/**
 * 查看圖片
 *
 * @author yubaoshan
 * @date 2020/6/30 00:20
 */
export function sysFileInfoPreview (parameter) {
  return axios({
    url: '/sysFileInfo/preview',
    method: 'get',
    params: parameter,
    responseType: 'arraybuffer'
  })
}

/**
 * 查看詳情文件信息表
 *
 * @author yubaoshan
 * @date 2020/6/30 00:20
 */
export function sysFileInfoDetail (parameter) {
  return axios({
    url: '/sysFileInfo/detail',
    method: 'get',
    params: parameter
  })
}

/**
 * 刪除文件信息表
 *
 * @author yubaoshan
 * @date 2020/6/30 00:20
 */
export function sysFileInfoDelete (parameter) {
  return axios({
    url: '/sysFileInfo/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 獲取在線文檔配置
 *
 * @author yubaoshan
 * @date 2020/6/30 00:20
 */
export function sysFileInfoGetOnlineConfig (parameter) {
  return axios({
    url: '/sysFileInfo/getOnlineFileConfig',
    method: 'get',
    params: parameter
  })
}
export const wxGetPreviewUrl = params => axios({
  url: '/wx/getPreviewUrl',
  method: 'get',
  params
})
